<template>
  <div class="casbee-page padding-left-layout padding-top-layout padding-right-layout">
    <page-title :title="$t('casbee.title_base_unit_casbee')" :textOnlyJp="$t('offset_transaction.title_only_jp')"/>
    <div v-if="!isCreateData && !isDetail" class="">
      <div class="title-name">
        <span class="title-name_label">{{ $t('casbee.label_item_name') }}</span>
        <casbee-input
          v-model="itemName"
          :value="itemName"
          :label="''"
          :type="'text'"
          :width="'335'"
          :placeholderText="$t('casbee.placeholder_pls_enter_name')"
          :errorMessage="inputErrors.itemName"
          :isItemName="true"
          @removeError="inputErrors.itemName = ''"
        />
      </div>
      <div class="select-wrapper">
        <span class="select-wrapper_label">{{ $t('casbee.label_classification') }}</span>
        <div class="pulldown-selects">
          <div class="select-classification">
            <span class="select-classification_label">{{ $t('casbee.label_building') }}</span>
            <default-pull-down-menu
              v-model="classificationModel"
              :items="classificationList"
              :initialValue="''"
              :width="'100%'"
              :boxWidth="'100%'"
              :height="heightPullDown"
              :isCasbeePulldown="true"
              :placeHolderText="$t('casbee.placeholder_pls_enter_name')"
              class="casbee-pulldown"
            />
          </div>
          <div class="select-category">
            <span class="select-classification_label">{{ $t('casbee.label_category') }}</span>
            <default-pull-down-menu
              v-model="categoryModel"
              :items="categoryList"
              :initialValue="''"
              :width="'100%'"
              :boxWidth="'100%'"
              :height="heightPullDown"
              :isCasbeePulldown="true"
              :placeHolderText="$t('casbee.placeholder_pls_enter_name')"
              class="casbee-pulldown"
            />
          </div>
        </div>
      </div>
      <div class="casbee-btn">
        <div class="btn cancel-btn" @click="handlerShowQuestionPopup()">
          <span>{{ $t('casbee.button_back') }}</span>
        </div>
      </div>
    </div>
    <div v-else class="casbee-create-data">
      <div class="title-name">
        <span class="title-name_label">{{ $t('casbee.label_item_name') }}</span>
        <casbee-input
          v-model="itemName"
          :value="itemName"
          :label="''"
          :type="'text'"
          :width="'335'"
          :placeholderText="$t('casbee.placeholder_pls_enter_name')"
          :errorMessage="inputErrors.itemName"
          :disabled="isDetailData"
          :isItemName="true"
          @removeError="inputErrors.itemName = ''"
        />
      </div>
      <div class="select-wrapper">
        <span class="select-wrapper_label">{{ $t('casbee.label_classification') }}</span>
        <div class="pulldown-selects">
            <div class="select-classification">
            <span class="select-classification_label">{{ $t('casbee.label_building') }}</span>
            <default-pull-down-menu
              v-model="classificationModel"
              :items="classificationList"
              :width="'100%'"
              :boxWidth="'100%'"
              :initialValue="classificationModel"
              :isCasbeePulldown="true"
              :height="heightPullDown"
              :disabled="isDetail"
              :placeHolderText="$t('casbee.pulldown_select_pls_select')"
              class="casbee-pulldown"
            />
          </div>
          <div class="select-category">
            <span class="select-classification_label">{{ $t('casbee.label_category') }}</span>
            <default-pull-down-menu
              v-model="categoryModel"
              :items="categoryList"
              :width="'100%'"
              :boxWidth="'100%'"
              :height="heightPullDown"
              :initialValue="categoryModel"
              :isCasbeePulldown="true"
              :disabled="isDetail"
              :placeHolderText="$t('casbee.pulldown_select_pls_select')"
              class="casbee-pulldown"
            />
          </div>
        </div>
      </div>
      <component :is="optionComponent" :detail-data="casbeeDetailData" @onHandlerCalculationData="handlerCalculationData" />

      <div class="calculation-wrapper mt-12" v-if="casbeeData.total || casbeeData.total === 0">
        <div class="wraper-title">{{ $t('casbee.label_result_calc') }}</div>
        <div class="total-wrapper">
          <div class="total-wrapper_item">
            <span class="total-wrapper_item--label">{{ casbeeData.label || $t("casbee.description_emission_co2_per_m2") }}</span>
            <div class="total-wrapper_item-value">
              <div class="total-wrapper_item-value--number">{{ prepareValueSource(casbeeData.total) | toCurrency  }}</div>
              <div v-if="categoryModelNew.includes(categoryModel)" class="total-wrapper_item-value--unit">t-CO2/㎡</div>
              <div v-else class="total-wrapper_item-value--unit">{{ $t('casbee.label_t_co2_per_year') }}</div>
            </div>
          </div>
          <div class="total-wrapper_item" v-if="casbeeData.totalInside || casbeeData.totalInside === 0">
            <span class="total-wrapper_item--label">{{ casbeeData.labelInside || $t('casbee.label_outdoor_corridor') }}</span>
            <div class="total-wrapper_item-value">
              <div class="total-wrapper_item-value--number">{{ prepareValueSource(casbeeData.totalInside) | toCurrency  }}</div>
              <div class="total-wrapper_item-value--unit">{{ $t('casbee.label_t_co2_per_year') }}</div>
            </div>
          </div>
          <div class="total-wrapper_item" v-if="casbeeData.totalOutside || casbeeData.totalOutside === 0">
            <span class="total-wrapper_item--label">{{ casbeeData.labelOutside || $t('casbee.label_indoor_corridor') }}</span>
            <div class="total-wrapper_item-value">
              <div class="total-wrapper_item-value--number">{{ prepareValueSource(casbeeData.totalOutside) | toCurrency  }}</div>
              <div class="total-wrapper_item-value--unit">{{ $t('casbee.label_t_co2_per_year') }}</div>
            </div>
          </div>
        </div>

        <div class="source mt-12">
          <div class="source-title">{{ $t('casbee.label_source') }}</div>
          <div class="source-description">{{ casbeeData.labelSource || sourceLabelDefault }}</div>
          <v-textarea
            class="source-input casbee"
            no-resize
            v-model="casbeeData.source"
            :class="inputErrors.source && 'source-error'"
            :auto-grow="true"
            :rows="1"
            @focus="inputErrors.source = ''"
            @input="convertTextToHalfWidth, setSource($event)"
          ></v-textarea>
          <p class="source-message--error" v-if="inputErrors.source">{{ inputErrors.source }}</p>

        </div>
      </div>
      <div class="casbee-btn mt-12" v-if="casbeeData.total || casbeeData.total === 0">
        <div class="btn create-btn" @click="handlerAddData()" :class="{'disabled': isDisabledDetailBtn}">
          <span>{{ textButtonEdit }}</span>
        </div>
        <div class="btn cancel-btn" @click="handlerShowQuestionPopup()">
          <span>{{ $t('casbee.button_back') }}</span>
        </div>
      </div>
    </div>
    <question-popup
      :titlePopupConfirm="`確認`"
      :dialog="questionDialog"
      :confirmText="$t('casbee.button_back')"
      :closeText="`キャンセル`"
      message="入力途中の内容が破棄されますが、戻りますか？"
      @submit="redirectToDbCustomize()"
      @close="questionDialog = false"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { ROUTES } from '@/router/constants';
import { CASBEE, HOME, DBCUSTOMIZE_TYPE, CASBEE_TYPE } from '@/constants/casbee';
import { createDbCustom, getCasbeeDetail, updateDbCustom } from "@/api/dbCustomization";
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import CommonButton from '@/components/utils/button.vue';
import QuestionPopup from '@/components/dialogs/question-popup';
import CasbeeInput from '@/components/casbee/input';
import merge from "lodash/merge";
import { formatBigNumber,math } from '@/concerns/newRegisterData/wijmo.helper'
import { convertNestedFullWidthToHalfWidth } from '@/utils/convertHalfWidth';
import { formatNumberRealNum } from '@/utils/convertNumber';

export default {
  components: { PageTitle, DefaultPullDownMenu, CommonButton, QuestionPopup, CasbeeInput },
  data() {
    return {
      classificationModelNew: [CASBEE.ARCHITECTURE, CASBEE.HOME],
      categoryModelNew: [HOME.CATEGORY_1, HOME.CATEGORY_1],
      itemName: null,
      classificationList: [
        {
          name: CASBEE.HOME,
          value: CASBEE.HOME,
        },
        {
          name: CASBEE.ARCHITECTURE,
          value: CASBEE.ARCHITECTURE,
        },
      ],
      classificationModel: null,
      categoryList: [
       {
          name: HOME.CATEGORY_1,
          value: HOME.CATEGORY_1,
        },
        {
          name: HOME.CATEGORY_11_13,
          value: HOME.CATEGORY_11_13,
        }
      ],
      categoryModel: null,
      isCreateData: false,
      casbeeData: {
        total: 0,
        totalInside: null,
        totalOutside: null,
        label: null,
        labelInside: null,
        labelOutside: null,
        labelSource: null,
        source: null,
        patternType: null,
      },
      isHasSourceData: true,
      questionDialog: false,
      sourceLabelDefault: this.$t("casbee.description_source"),
      isDetail: false,
      inputErrors: {
        itemName: '',
        source: '',
      },
      casbeeDetailData: {},
      heightPullDown: '32px',
      isClicked: false,
    };
  },
  async created() {
    this.isDetail = Number.isInteger(parseInt(this.$route.params.id)) ? true : false;
    if (this.isDetail) {
      const detailData = await getCasbeeDetail(parseInt(this.$route.params.id));
      
      this.casbeeDetailData = detailData;
      this.prepareDetailData();
    }
  },
  mounted() {
    let items = [
      {
        text: this.$t("casbee.hyperlink_home"),
        disabled: false,
        href: ROUTES.PRODUCTS_EMISSION,
      },
      {
        text: this.$t("casbee.hyperlink_setting"),
        disabled: false,
        href: ROUTES.PRODUCTS_EMISSION + ROUTES.SETTING,
      },
      {
        text: this.$t("casbee.hyperlink_db_custom_management"),
        disabled: true,
        href: ROUTES.PRODUCTS_EMISSION + ROUTES.CREATE_DATA_CUSTOMIZE,
      },
      {
        text: this.$t("casbee.label_base_unit_casbee"),
        disabled: true,
        href: ROUTES.PRODUCTS_EMISSION + ROUTES.CREATE_DATA_CUSTOMIZE,
      },
    ];
    this.updateBreadCrumb(items);
    this.heightPullDown = this.getHeight();
    this.onWindowResize();
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    formatNumberRealNum,
    convertTextToHalfWidth() {
      this.casbeeData.source = convertNestedFullWidthToHalfWidth(this.casbeeData.source);
    },
    showCreateData() {
      if (this.classificationModel !== null && this.categoryModel !== null) {
        this.isCreateData = true;
      }
    },
    prepareValueSource(number) {
      let result = '';
      result = typeof number === 'string' ? number : math.format(number, {notation: 'fixed'});
      let indexSlice = result.includes('-') ? 26 : 25
      if(result === '-0.0000'){
        return '0'
      }
      return result.substring(0, indexSlice);
    },
    prepareData() {
      const categorys = this.categoryModel === HOME.CATEGORY_1 ? [1] : [11, 13];
      const unit = 'm2';
      const unit_source = this.categoryModelNew.includes(this.categoryModel) ? 't-CO2/m2' : 't-CO2/m2・年 ';
      const contractor = this.$store.state.userData.contractor;
      let data = [];

      categorys.forEach((category) => {
        let totalData = {
          scope_id: 3,
          category_id: category,
          contractor_id: contractor,
          item_name: this.casbeeData.sourceInside ? this.itemName?.slice(0, 123) :  this.itemName?.slice(0, 128),
          unit: unit,
          value_source: this.prepareValueSource(this.casbeeData.total),
          unit_source: unit_source,
          source: this.casbeeData.sourceInside ? this.casbeeData.source?.slice(0, 250) : this.casbeeData?.source.slice(0, 255),
          status: true,
          note: '',
          pattern_type: this.casbeeData.patternType,
        };
        data.push(totalData);

        if (this.casbeeData.sourceInside) {
          const source = this.casbeeData.source?.slice(0, 250)
          const itemName = this.itemName?.slice(0, 123)
          let dataInside = [
            {
              scope_id: 3,
              category_id: category,
              contractor_id: contractor,
              item_name: itemName + '_屋外廊下',
              unit: unit,
              value_source: this.prepareValueSource(this.casbeeData.totalInside),
              unit_source: unit_source,
              source: source + '_屋外廊下',
              status: true,
              note: '',
              pattern_type: this.casbeeData.patternType,
            },
            {
              scope_id: 3,
              category_id: category,
              contractor_id: contractor,
              item_name: itemName + '_屋内廊下',
              unit: unit,
              value_source: this.prepareValueSource(this.casbeeData.totalOutside),
              unit_source: unit_source,
              source: source + '_屋内廊下',
              status: true,
              note: '',
              pattern_type: this.casbeeData.patternType,
            }
          ]

          data = [...data, ...dataInside]
        }
      });
      return data;
    },
    modifyData() {
      // console.log(this.casbeeDetailData.data);
      // console.log(this.prepareData());
    }, 
    prepareDetailData() {
      const dataDBCustomize = this.getSourceDbCustomize();
      this.itemName = dataDBCustomize.item_name;
      this.casbeeData.source = dataDBCustomize.source
      switch (dataDBCustomize.pattern_type) {
        case CASBEE_TYPE.HOME:
          this.classificationModel = CASBEE.HOME;
          this.categoryModel = HOME.CATEGORY_1;
          break;
        case CASBEE_TYPE.HOME_CHOOSE_CONDITION:
        case CASBEE_TYPE.HOME_ENTER_FREELY:
          this.classificationModel = CASBEE.HOME;
          this.categoryModel = HOME.CATEGORY_11_13;
          break;
        case CASBEE_TYPE.ARCHITECTURE:
          this.classificationModel = CASBEE.ARCHITECTURE;
          this.categoryModel = HOME.CATEGORY_1;
          break;
        case CASBEE_TYPE.ARCHITECTURE_APARTMENT_CHOOSE_CONDITION:
        case CASBEE_TYPE.ARCHITECTURE_APARTMENT_ENTER_FREELY:
        case CASBEE_TYPE.ARCHITECTURE_OUTSIDE_CHOOSE_CONDITION:
        case CASBEE_TYPE.ARCHITECTURE_OUTSIDE_ENTER_FREELY:
          this.classificationModel = CASBEE.ARCHITECTURE;
          this.categoryModel = HOME.CATEGORY_11_13;
          break;
        default:
          break;
      }
    },
    handlerAddData() {
      if (this.isDisabledDetailBtn) return
      if (this.itemName === null || this.itemName.trim() === '') {
        this.inputErrors.itemName = this.$t("casbee.error_enter_input_title_casbee");
      }
      if (this.casbeeData.source === null || this.casbeeData.source.trim() === '') {
        this.inputErrors.source = this.$t("casbee.error_enter_input_source_casbee");
      }
      if (this.isValidData) {
        let payload = { 
          data: this.prepareData(),
          dataDetail: {
            type: DBCUSTOMIZE_TYPE.CASBEE,
            content_json: this.casbeeData.content_json,
          },
        };
        if (this.isClicked) return;
        this.isClicked = true;
        if (this.isDetailData) {
          let data = this.prepareDataUpdateDetail();
          updateDbCustom({ data: data}).then(res => {
           this.$router.push({ path: ROUTES.PRODUCTS_EMISSION + ROUTES.CREATE_DATA_CUSTOMIZE}) //FOR: Dummy
          })
        } else {
          createDbCustom(payload).then(response => {
            this.$router.push({ path: ROUTES.PRODUCTS_EMISSION + ROUTES.CREATE_DATA_CUSTOMIZE, query: { registeCasbe: response.record_insert.length }})
          })
        }
      }
    },
    prepareDataUpdateDetail() {
      const idDBCustomize = this.$route.query?.id_db_customize;
      const dbCustomizeById = this.casbeeDetailData.data.find(item => 
        item.id === Number(idDBCustomize)
      )
      const DataRowCustomize =
        [
          {
            id: dbCustomizeById.id,
            scope_id: dbCustomizeById.scope_id,
            category_id: dbCustomizeById.category_id,
            contractor_id: dbCustomizeById.contractor_id,
            item_name: dbCustomizeById.item_name,
            unit: dbCustomizeById.unit,
            value_source: dbCustomizeById.value_source,
            unit_source: dbCustomizeById.unit_source,
            source: dbCustomizeById.source?.slice(0, 255),
            status: dbCustomizeById.status,
            note: dbCustomizeById.note
          }
        ]
      return DataRowCustomize;
    },
    handlerCalculationData(data) {
      this.casbeeData = data;
      this.isHasSourceData = true;
      this.inputErrors.source = ''
    },
    handlerShowQuestionPopup() {
      const isEdittingData = this.casbeeData?.source !== null && this.casbeeData?.source !== undefined && this.casbeeData?.source?.trim() !== '';
      if (isEdittingData && !this.isDetailData) {
        this.questionDialog = true;
      } else {
        return this.$router.push({ path: ROUTES.PRODUCTS_EMISSION + ROUTES.CREATE_DATA_CUSTOMIZE });
      }
    },
    redirectToDbCustomize() {
      return this.$router.push({ path: ROUTES.PRODUCTS_EMISSION + ROUTES.CREATE_DATA_CUSTOMIZE });
    },
    getHeight() {
      return window.innerWidth >= 1024 ? '32px' : '32px';
    },
    onWindowResize() {
      window.addEventListener('resize', this.handleWindowResize);
    },
    handleWindowResize() {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.heightPullDown = this.getHeight();
      }, 100);
    },
    setSource(valSource) {
      if(this.isDetailData) {
        this.casbeeDetailData.data.map(
          val => {val.source = valSource}
        );
      }
    },
    getSourceDbCustomize() {
      const idDBCustomize = this.$route.query?.id_db_customize;
      const dataDBCustomize = this.casbeeDetailData?.data.find(element => 
                                element.id === Number(idDBCustomize)
                              )
      return dataDBCustomize
    }
  },
  computed: {
    optionComponent() {
      let page = null;
      if (this.classificationModel === CASBEE.HOME) {
        if (this.categoryModel === HOME.CATEGORY_1) {
          page = 'home/create-new';
        } else {
          page = 'home/cat11-13/index';
        }
      } else {
        if (this.categoryModel === HOME.CATEGORY_1) {
          page = 'architecture/create-new';
        } else {
          page = 'architecture/index';
        }
      }
      this.handlerCalculationData({});
      return () => import(`@/views/products/emissions/dbCustomize/${page}.vue`);
    },
    isDisableBtn() {
      if(this.isDetailData) return true;
      return this.classificationModel === null || this.categoryModel === null;
    },
    isValidData() {
      return !(Object.values(this.inputErrors).find(item => item !== ''));
    },
    isDetailData() {
      return Object.keys(this.casbeeDetailData).length > 0 ;
    },
    textButtonEdit() {
      return !this.isDetail ? 'この原単位を登録' : '更新';
    },
    isDisabledDetailBtn(){
      if (this.isDetailData) {
        return this.prepareDataUpdateDetail()?.every(item => item.source === '');
      }
      return false
    }
  },
  watch: {
    itemName(val){
      this.inputErrors.itemName = 
        (this.isCreateData && !val?.trim()) 
        ? this.$t("casbee.error_enter_input_title_casbee")
        : 
        ''
    },
    isCreateData(val){
      this.inputErrors.itemName = 
        (val && !this.itemName?.trim()) 
        ? this.$t("casbee.error_enter_input_title_casbee")
        : 
        ''
    },
    casbeeData: {
      handler(newVal) {
        if(newVal.sourceInside) {
          this.casbeeData.sourceInside = this.casbeeData.source + '_屋外廊下';
          this.casbeeData.sourceOutside = this.casbeeData.source + '_屋内廊下';
        }
        if(this.isDetailData) {
          const dataDBCustomize = this.getSourceDbCustomize();
          this.casbeeData.source = dataDBCustomize.source
        }
      },
      deep: true,
    },
    optionComponent(){
      this.showCreateData()
    },
  },
}
</script>   
<style lang="scss" scoped>
.casbee-page {
  font-family: $notoSanFont;
  .title-name {
    margin-bottom: 48px;
    &_label {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      margin-bottom: 8px;
    }
    .input-elic {
      width: 300px;
      height: 32px;
    }
  }
  .duration {
      margin-bottom: 48px;
      &-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        display: block;
        margin-bottom: 8px;
      }
      &-value {
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.05em;
        color: $monoBlack;
      }
    }
  .select-wrapper {
    margin-bottom: 48px;
    &_label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
    }
    .pulldown-selects {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      .select-classification {
        &_label {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: $monoDark;
          display: block;
          margin-bottom: 8px;
        }
      }
      .select-category {
        .select-classification_label {
          display: block;
          margin-top: 8px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .casbee-btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    .btn {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 24px 18px;
      border-radius: 4px;
      width: 100%;
      span {
        color: $monoWhite;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.03em;
      }
      &:hover {
        cursor: pointer;
      }
      &.disabled {
        cursor: unset;
      }
      &:active {
        border: unset;
      }
    }
    .create-btn {
      background-color: $goldMid;
      height: 56px;
      min-width: 240px;
      &:hover {
        background-color: $goldLight;
      }
      &.disabled {
        background-color: $monoLight;
        span {
          color: $monoMid;
        }
        &:active {
          border: unset;
          background-color: $monoLight;
        }
      }
      &:active {
        border: 2px solid $blueMid;
        background-color: $goldMid;
      }
    }
    .cancel-btn {
      background-color: #9D9D9D;
      height: 56px;
      min-width: 240px;
      &:hover {
        background-color: #818080;
      }
    }
  }
  .error-message {
    color: $redMid;
    font-family: 'Source Han Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.05em;
    margin-top: 4px;
  }
  .casbee-create-data {
    .calculation-wrapper {
      .wraper-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        margin-bottom: 8px;
      }
      .total-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        &_item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 8px;
          min-width: 220px;
          min-height: 68px;
          &--label {
            font-weight: 400;
            font-size: 15px;
            line-height: 28px;
            letter-spacing: 0.05em;
            color: $monoMid;
          }
          &-value {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            padding: 0px;
            gap: 8px;
            min-width: 181px;
            min-height: 35px;
            flex-wrap: wrap;
            &--number {
              font-weight: 700;
              font-size: 22px;
              line-height: 32px;
              color: $monoBlack;
              font-family: $notoSanFont;
            }
            &--unit {
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              color: $monoBlack;
              font-family: $notoSanFont;
              min-width: 80px;
            }
          }
        }
      }
      .source {
        &-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.03em;
          color: $monoBlack;
          display: block;
          margin-bottom: 8px;
        }
        &-description {
          font-weight: 400;
          font-size: 15px;
          line-height: 28px;
          letter-spacing: 0.05em;
          color:$monoBlack;
          white-space: pre-line;
          display: block;
          margin-bottom: 8px;
        }
        .input-elic {
          width: 100%;
        }
        &-input {
          margin-top: 8px;
          background: #FFFFFF;
          // border: 1px solid $bgCusLight;
          box-shadow: inset 0px 2px 2px rgba(160, 181, 186, 0.15), inset 0px 2px 6px rgba(160, 181, 186, 0.5);
          border-radius: 4px;
          height: 88px;
          height: max-content;
          &--pc {
            display: none;
          }
          &.source-error {
            position: relative;
            &::after {
              content: '';
              position: absolute;
              width: 16px;
              height: 16px;
              right: 15px;
              top: 7px;
              background: url('../../../../assets/icons/casbee/warning.svg');
            }
          }
        }
        &-warning {
          position: absolute;
          top: 20px;
        }
        &-message {
          &--error {
            color: $redMid;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.05em;
            position: relative;
            margin-top: 4px;
            max-width: 955px;
          }
        }
      }
    }
    .duration {
      margin-bottom: 48px;
      &-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        display: block;
        margin-bottom: 8px;
      }
      &-value {
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.05em;
        color: $monoBlack;
      }
    }
    .select-wrapper {
      .select-wrapper_label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
    }
  }
  .page-title {
    .page-title__text {
    }
  }
  ::v-deep .pull-down-adjust .selection-box-bridge .input-box {
    width: calc(100vw - 50px);
  }
}
@include desktop {
  .casbee-page {
  .title-name {
    margin-bottom: 48px;
    .title-name_label{
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.05em;
      margin-bottom: 12px;
    }
  }
  .select-wrapper {
    margin-bottom: 48px;
    .select-wrapper_label{
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.05em;
    
    }
    .pulldown-selects {
      display: flex;
      flex-direction: row;
      margin-top: 12px;
      .select-classification {
        width: 160px;
        margin-right: 20px;
      }
      .select-category {
        width: 176px;
      }
      .select-classification_label {
          margin-top: unset !important;
          margin-bottom: 12px !important;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: $monoDark;
        }
    }
  }
  .casbee-btn {
    display: flex;
    flex-direction: row;
    gap: 24px;
    .btn {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 24px 18px;
      border-radius: 4px;
      width: 200px;
      span {
        color: $monoWhite;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.03em;
      }
      &:hover {
        cursor: pointer;
      }
      &.disabled {
        cursor: unset;
      }
      &:active {
        border: unset;
      }
    }
    .create-btn {
      background-color: $goldMid;
      height: 56px;
      min-width: 240px;
      &:hover {
        background-color: $goldLight;
      }
      &.disabled {
        background-color: $monoLight;
        span {
          color: $monoMid;
        }
        &:active {
          border: unset;
          background-color: $monoLight;
        }
      }
      &:active {
        border: 2px solid $blueMid;
        background-color: $goldMid;
      }
    }
    .cancel-btn {
      background-color: #9D9D9D;
      height: 56px;
      min-width: 240px;
    }
  }
  .error-message {
    color: $redMid;
    font-family: 'Source Han Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.05em;
    margin-top: 4px;
  }
  .casbee-create-data {
    .duration {
      margin-bottom: 48px;
      &-label {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.05em;
        margin-bottom: 12px;
      }
      &-value {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .select-wrapper {
    margin-bottom: 48px;
    .select-wrapper_label{
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.05em;
    
    }
    
  }
    
    .calculation-wrapper {
      .wraper-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.05em;
        color: $monoBlack;
        margin-bottom: 12px;
      }
      .total-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 40px;
        &_item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 12px;
          min-width: 220px;
          min-height: 67px;
          &--label {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: $monoDark;
          }
          &-value {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            padding: 0px;
            gap: 8px;
            min-width: 181px;
            min-height: 35px;
            &--number {
              font-weight: 700;
              font-size: 24px;
              line-height: 35px;
              color: $monoBlack;
              font-family: $notoSanFont;
            }
            &--unit {
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              color: $monoBlack;
              font-family: $notoSanFont;
            }
          }
        }
      }
      .source {
        &-title {
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: 0.05em;
          color: $monoBlack;
          margin-bottom: 12px;
        }
        &-description {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $monoBlack;
          white-space: pre-line;
          margin-bottom: 12px;
        }
        .input-elic {
          width: 100%;
        }
        &-input {
          // display: none;
          margin-top: 12px;
          box-shadow: unset;
          &--pc {
            display: block;
          }
        }
        &-warning {
          // display: none;  
        }
        &-message {
          &--error {
            display: none; 
            display: block;  
          }
        }
        &.source-error {
            position: relative;
            border: 1px solid $redMid;
            &::after {
              content: '';
              position: absolute;
              width: 16px;
              height: 16px;
              right: 15px;
              top: 12px;
              background: url('../../../../assets/icons/casbee/warning.svg');
            }
          }
      }
    }
  }
}

}
</style>
